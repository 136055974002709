import { Link } from "react-router-dom";

const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/about-images.png)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Mr. Carlos</h3>
                                <p className="about-text">
                                    Я профессиональный барбер-стилист из солнечной Гаваны, опыт работы 12 лет. Выполняю стрижки любой сложности и стиля как машинкой, так и ножницами, оформляю бороду машинкой/шейвером/тримером/лезвием. Создаю красивый стиль, подходящий по возрасту.
                                    Последние 5 лет я работал в одном из топовых барбершопов в Подольске и стал Top Barber.

                                </p>
                                <p>
                                    Я очень люблю свою профессию и получаю удовольствие от своей работы! Для меня самый большой успех - когда мои клиенты уходят довольными результатом моей работы на 100%. Тот, кто попадает в мои опытные руки, не остаётся равнодушным к результату работы.
                                </p>
                                <p>
                                    Спешите записаться на стрижку, много всего делаю в подарок в дополнение к основным услугам:
                                    контур бороды, лëгкий камуфляж, hair tattoo (рисунок любой сложности), удаление воском 1, 2, 3 зон (брови/нос/уши), оформление бровей, укладка волос.
                                </p>
                                <div><a className="slider-btn" href="https://dikidi.net/893196?p=1.pi-ssm-si&s=8656462" target="_blank" rel="noopener noreferrer">Записаться</a></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
