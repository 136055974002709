const Footer = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                <div className="footer-info-box text-center">
                    <h4 className="title">Приходите к мне!</h4>
                    <h2 className="mail">
                        <a href="mailto:someone@example.com">carlosmister256@gmail.com</a>
                    </h2>
                    <h2 className="mail">
                        <a href="tel:+79636629009">+7 963 662 90 09</a>
                    </h2>
                    <h2 className="mail">
                        <a href="https://yandex.com/maps/-/CCUfbEBEdC">Пионерская улица, 15к3, подьезд 5. Подольск </a>
                    </h2>
                </div>

                <div className="footer-copyright-social">
                    <div className="footer-copyright">
                        <p>
                            &copy; 2023 <span> Andy Martel</span>
                        </p>
                    </div>
                    <div className="footer-social">
                        <ul className="social">
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="twitter"
                                    href="https://dikidi.net/893196?p=1.pi-ssm-si&s=8656462"
                                >
                                    Dikidi
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
