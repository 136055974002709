import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Work = ({ data }) => {
    const cate = data.categories.map((value, idx) => {
        return (
            <span className="d-inline" key={idx}>
                {value}
                {idx !== data.categories.length - 1 && " , "}
            </span>
        );
    });
    return (
        <div className="single-project-slide">
            <div className="thumb">
                <div>
                    <a className="slider-btn" href="https://dikidi.net/893196?p=1.pi-ssm-si&s=8656462" target="_blank" rel="noopener noreferrer">

                        <img
                            className="fit-image"
                            src={process.env.PUBLIC_URL + data.media.thumb}
                            alt="Product"
                        />
                    </a>
                </div>
            </div>

            <div className="content">
                <h4 className="subtitle">{cate}</h4>
                <h3 className="title">

                    <a className="slider-btn" href="https://dikidi.net/893196?p=1.pi-ssm-si&s=8656462" target="_blank" rel="noopener noreferrer">


                        {data.title}
                    </a>
                </h3>
            </div>
        </div>
    );
};

Work.propTypes = {
    data: PropTypes.object,
};

export default Work;
